// extracted by mini-css-extract-plugin
export var bottom = "PlasmicSelect__Overlay-module--bottom--aGc2N";
export var bottomrelativePlacement_top = "PlasmicSelect__Overlay-module--bottomrelativePlacement_top--6Lk4P";
export var left = "PlasmicSelect__Overlay-module--left--hXRU8";
export var leftrelativePlacement_right = "PlasmicSelect__Overlay-module--leftrelativePlacement_right--UJ3fA";
export var main = "PlasmicSelect__Overlay-module--main--jx6sd";
export var middle = "PlasmicSelect__Overlay-module--middle--ZGnpV";
export var right = "PlasmicSelect__Overlay-module--right--XGga7";
export var rightrelativePlacement_left = "PlasmicSelect__Overlay-module--rightrelativePlacement_left--g7WeW";
export var root = "PlasmicSelect__Overlay-module--root--vEu+w";
export var top = "PlasmicSelect__Overlay-module--top--YdNfU";
export var toprelativePlacement_bottom = "PlasmicSelect__Overlay-module--toprelativePlacement_bottom--DSV8K";