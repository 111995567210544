// extracted by mini-css-extract-plugin
export var __wab_slot = "PlasmicSelect__OptionGroup-module--__wab_slot--cPPhM";
export var __wab_slotStringWrapper = "PlasmicSelect__OptionGroup-module--__wab_slot-string-wrapper--DpFWx";
export var __wab_text = "PlasmicSelect__OptionGroup-module--__wab_text--2pgXx";
export var option__mHl5E = "PlasmicSelect__OptionGroup-module--option__mHl5E--KI9BD";
export var option__mklyc = "PlasmicSelect__OptionGroup-module--option__mklyc--eLHVY";
export var optionsContainer = "PlasmicSelect__OptionGroup-module--optionsContainer--wyeos";
export var root = "PlasmicSelect__OptionGroup-module--root--gIvFh";
export var separator = "PlasmicSelect__OptionGroup-module--separator--8rf7g";
export var slotTargetTitle = "PlasmicSelect__OptionGroup-module--slotTargetTitle--cbK8q";
export var titleContainer = "PlasmicSelect__OptionGroup-module--titleContainer--iujrc";